// APIで422のエラーとなった場合のError
// e.messageにバリデーションメッセージが入る
export class BadRequestException extends Error {
  constructor(message:string) {
    super(message)
    this.name = 'BadRequestException'
    this.statusCode = 422
  }

  private statusCode: number

  public getStatusCode(): number {
    return this.statusCode
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBadRequestException = (error:any): error is BadRequestException => {
  return error?.name === 'BadRequestException'
}